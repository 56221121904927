import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bg_text" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "list" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "desc" }
const _hoisted_6 = ["src"]

import { lang } from '@/lang';
import { computed } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'ProductFeatures',
  setup(__props) {

const ListData = computed(() => [
  {
    AppFeature: lang.v.AppFeaturePro1,
    AppFeatureDesc: lang.v.AppFeatureDescPro1,
    AppFeatureImg: require('@/assets/img/newHome/AppFeature1.png'),
  },
  {
    AppFeature: lang.v.AppFeaturePro2,
    AppFeatureDesc: lang.v.AppFeatureDescPro2,
    AppFeatureImg: require('@/assets/img/newHome/AppFeature2.png'),
  },
  {
    AppFeature: lang.v.AppFeaturePro3,
    AppFeatureDesc: lang.v.AppFeatureDescPro3,
    AppFeatureImg: require('@/assets/img/newHome/AppFeature3.png'),
  },
  {
    AppFeature: lang.v.AppFeaturePro4,
    AppFeatureDesc: lang.v.AppFeatureDescPro4,
    AppFeatureImg: require('@/assets/img/newHome/AppFeature4.png'),
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(_toDisplayString(_unref(lang).v.ProductFeaturesPro) + " ", 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(lang).v.ProductFeaturesPro), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ListData.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "list_item"
          }, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(item.AppFeature), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.AppFeatureDesc), 1),
            _createElementVNode("img", {
              src: item.AppFeatureImg,
              alt: ""
            }, null, 8, _hoisted_6)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})