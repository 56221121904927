import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "black_bg" }
const _hoisted_2 = ["onClick"]

import { lang } from '@/lang';
import router from '@/router';
import { onDownload } from '@/utils/download';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BottomBack',
  setup(__props) {

const handleMovshot = () => {
  router.push({ name: 'Home' });
};

const handleContact = () => {
  router.push({ name: 'Contact' });
};
const List = [
  {
    title: computed(() => lang.v.Movshot),
    click: handleMovshot,
  },
  {
    title: computed(() => lang.v.Contact),
    click: handleContact,
  },
  {
    title: computed(() => lang.v.download),
    click: onDownload,
  },
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(List, (item, index) => {
      return _createElementVNode("div", {
        key: index,
        class: "item",
        onClick: item.click
      }, [
        _createElementVNode("p", null, _toDisplayString(item.title), 1)
      ], 8, _hoisted_2)
    }), 64))
  ]))
}
}

})